<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 text-uppercase v-title-cadastro">
      {{ title }}
    </v-card-title>

    <MasterDetail
      :contextOptions="contextOptions"
      :canDelete="false"
      :canEdit="false"
      :cols="cols"
      :hasFilter="true"
      :hasNewButton="false"      
      :selection.sync="rubricasSelecionadas"
      selectionCol
      ref="masterDetail"
      :customResource="customResource"
    ></MasterDetail>

    <FormModal
      :cols="statusFields"
      maxWidth="450px"
      :opened.sync="statusModalOpen"
      :opts="opts"
      title="Alterar Status"
      :value.sync="statusModal"
      @save="saveStatus"
    ></FormModal>
  </v-card>
</template>

<script>
import { StatusRubricasEnum } from '@/core/enums/status-rubricas';
import { mapGetters } from 'vuex';

export default {
  components: {
    "FormModal": () => import("@/components/form-modal.vue"),
    "MasterDetail": () => import("@/components/master-detail.vue"),
  },
  computed: {
    ...mapGetters(['clientId', 'selectedClient']),
    cols: function () {
      return [
        {
          key: 'codigo',
          name: 'Código',
        },
        {
          key: 'descricao',
          name: 'Descrição',
        },
        {
          key: 'status',
          name: 'Status',
        },
        {
          key: 'pareada',
          name: 'Pareada',
        },
        {
          key: 'modificado_em',
          name: 'Última atualização',
          type: this.$fieldTypes.DATETIME,
        },
      ];
    },
    contextOptions: function() {
      return [{
        name: "Alterar status",
        limit: 1,
        cb: (row) => {
          const catalogoIds = Array.isArray(this.rubricasSelecionadas) && this.rubricasSelecionadas.length > 0 ? this.rubricasSelecionadas.map(({ id }) => id) : [row.id];
          this.statusModal = { catalogoIds };
          this.statusModalOpen = true;
        },
      }];
    },
    statusFields: function () {
      return [
        {
          key: 'status',
          name: 'Status',
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: 'status', key: "nome", name: "nome" },
          rules: [{ rule: 'required'}]
        },
      ];
    },    
    resourceUrl: function () {
      return `v1/rh/clientes/${this.clientId}/templateCatalogo`;
    },
    customResource: function() {
      const resource = this.apiResource(`v1/rh/clientes/${this.clientId}/templateCatalogo`)
      return {
        ...resource,
        get(...args) {
          return resource.get(...args).then((result) => {
            if(Array.isArray(result)) {              
              return result.map(({...result}) => ({
                ...result,
              }));        
            }
          })
        }
      }
    },
    title: function () {
      if (this.selectedClient) {
        return `Rubricas Importadas de ${this.selectedClient.fantasia}`;
      }

      return `Rubricas Importadas`;
    },
  },
  methods: {
    saveStatus(modalValues) {
      const resource = this.apiResource(`v1/rh/clientes/${this.clientId}/templateCatalogo`);
      resource.save({...modalValues}).then((result) => {
        if(result.code == 200) {
          this.$notify({
            group: "geral",
            duration: 5000,
            type: "success",
            title: "Alterações salvas",
            text: "Status da(s) rubricas foram alterados",
          })    
        }          
        this.$refs.masterDetail.doLoad();
      })
         
      this.statusModalOpen = false;
    },
  },
  data: function() {
    return {
      rubricasSelecionadas: [],
      statusModal: {},
      statusModalOpen: false,
      opts: {
        status: [
          {nome: StatusRubricasEnum.ELEGIVEL},
          {nome: StatusRubricasEnum.NAO_ELEGIVEL},
          {nome: StatusRubricasEnum.PENDENTE}
        ]
      }
    }
  }
}
</script>
